import React, {useState, useEffect} from "react"
import "../index.css"
import {Link} from "react-router-dom"
import {
    Box, 
    Container
} from "@mui/material"
import { getAllRecipes } from "../controllers/RecipeController"
import RecipeCard from "./RecipeCard"
import Button from '@mui/material/Button';
import AppBar from "@mui/material/AppBar";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from "@mui/material/Grid"
import FeaturedRecipeCard from "./FeaturedRecipeCard"


const LandingPage = (props) => {

    document.title=props.title

    const [recipes, setRecipes] = useState([""])
    const [loadReady, setLoadReady] = useState(false)
    const [indexOfFeaturedRecipe, setIndexOfFeaturedRecipe] = useState(0)
    
    const featuredRecipeDbId = "zjiefiRNtprPPAlvGv75"
    let indexStopped = 0;

    useEffect(() => {
        getAllRecipes()
        .then((dbRes) => {
            let index = dbRes.findIndex(recipe => {
                return recipe.dbId === featuredRecipeDbId
            })
            setIndexOfFeaturedRecipe(index)
            setRecipes(dbRes)
            setLoadReady(true)
        })
        .catch((err) => console.log(err))
    }, [])

    let recipesShowing = 0;

    return(
        <>
        {loadReady &&
                        <Box>
                        <Box className="landingPageTopOfFold">
                        <h1 className="oregano-regular" style={{}}>Hotpatata</h1>
                    {/* <h1 style={{marginTop: "30px"}}>What's for dinner?</h1> */}
                    <h2 style={{paddingTop: "20px", fontSize: "35px", color: "white"}}>Break the weekly routine</h2>
                    <p className="landingPageText" style={{color: "white"}}>Join the community to share your own recipes and curate your own collection of delicious dishes</p>
                    <a style={{textAlign: "center"}} href="/signUp"><Button size="large" variant="contained" sx={{marginTop: "16px", boxShadow: "none", backgroundColor: "white", color: "black"}}>Get Started</Button></a>
                    <a href="/signIn" style={{textDecoration:"none"}}><p style={{color: "white"}}>Or, login here</p></a>
                    <img className="landingPageImage" style={{}} src="https://firebasestorage.googleapis.com/v0/b/hotpatata-da973.appspot.com/o/landingPageImg.png?alt=media&token=42856254-7f0f-4dfc-95ff-c0cc638e4212"/>
                </Box>
                    <h2 style={{paddingLeft: "10px", textAlign: "center"}}>What's Cooking?</h2>
                    <Grid container sx={{padding: "10px"}} spacing={2}>
                    {/* <Grid item xs={12}>
                        <FeaturedRecipeCard
                            recipeDbId={recipes[indexOfFeaturedRecipe].dbId}  
                            landingPage={true}
                            recipeTitle={recipes[indexOfFeaturedRecipe].title}
                            prepTime={recipes[indexOfFeaturedRecipe].prepTime}
                            cookTime={recipes[indexOfFeaturedRecipe].cookTime}
                            recipePicture={recipes[indexOfFeaturedRecipe].recipePictureUrl}
                            fromLandingPage={true}
                        />
                        </Grid> */}
                    {recipes.map((recipe, index) => {
                        if(recipe.recipePictureUrl) {
                        return (

                            <Grid key={recipe.dbId} item xs={6} sm={4} md={3}>
                            <RecipeCard
                            landingPage={true}
                            recipeTitle={recipe.title}
                            prepTime={recipe.prepTime}
                            cookTime={recipe.cookTime}
                            recipeDbId={recipe.dbId}
                            userPicture={recipe.userPicture}
                            userUsername={recipe.userUsername}
                            recipePicture={recipe.recipePictureUrl}
                            recipeTags={recipe.tags}
                            fromLandingPage={true}
                            auth={false}/> 
                            </Grid> 
                        )
                    } else {
                        return
                    }})}
                </Grid>
                <p style={{textAlign: "center"}}>&copy; {new Date().getFullYear()} HotPatata</p>
            </Box>
                }
                </>
    )
}

export default LandingPage