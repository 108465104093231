import React, {useState, useEffect} from "react"

import { addRecipe, updateRecipe, getRecipe, deleteRecipe } from "../controllers/RecipeController"
import { getDownloadURLForPicture, deleteImage } from "../firebase/storageFunctions";

import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Types from "./datapoints/recipeTypes"
import Container from "@mui/material/Container"
import BackArrow from "./uiComponents/PageHeader"
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from "@mui/material/FormControl";
import Select from '@mui/material/Select';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InputAdornment from "@mui/material/InputAdornment";
import AuthPagesWrapper from "./AuthPagesWrapper"
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { addRecipePicture } from "../firebase/storageFunctions";
import { getAnalytics, logEvent } from "firebase/analytics";

const analytics = getAnalytics()

const tags = [
    'Family Recipe',
    'Vegetarian',
    'Less than 30',
    'One Pot',
    'Low Carb'
  ];

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      },
    },
  };

const AddRecipe = (props) => {

    const [recipeData, setRecipeData] = useState({
        summary: "",
        title: "",
        cookTime: 0,
        prepTime: 0,
        servings: 0,
        tags: [],
        type: "",
        ingredients: [{
            amount: "1/8",
            measurement: "tsp.",
            ingredient: ""
        }],
        instructions: [""],
    })
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("")
    const [isModification, setIsModification] = useState(false)
    const [imageUrl, setImageUrl] = useState("")
    const [totalSummaryCount, setTotalSummaryCount] = useState(0)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [types, setTypes] = useState(Types)

    let backpath = "/"

    if(props.recipeId) {
        backpath = `/viewRecipe/${props.recipeId}`
    }

        useEffect(() => {
            document.title=props.title
            if(props.recipeId) {
            setIsModification(true)
            getRecipe(props.recipeId)
            .then(recipe => {
                if(props.userDbId === recipe.userDbId) {
                setIsAuthorized(true)
                let tempRecipeData = {...recipe}
                if(!tempRecipeData.summary) {
                tempRecipeData.summary = ""
                }
                setRecipeData(tempRecipeData)
                setImageUrl(recipe.recipePictureUrl)
                if(recipe.summary) {
                setTotalSummaryCount(recipe.summary.length)
                }} else {
                    navigate("/home")
                }
            })
        }
        },[props.title])

    const navigate = useNavigate()

    const navigateHome = () => {
        navigate("/home")
    }

    const handleStepForwardChange = () => {
        if(pageNumber < 3) {
        setPageNumber(pageNumber + 1)
        }
    }

    const handleStepBackChange = () => {
        if(pageNumber !== 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const handleRecipeImageChange = (e) => {
        let fullPath = ""
        let tempRecipeData = {...recipeData}
        let recipePictureName = Math.floor(Math.random() * 10000000000000)
            if(props.recipeId) {
                if(recipeData.fullPath) {
            deleteImage(recipeData.fullPath)
                }   
            addRecipePicture(recipePictureName, e.target.files[0], e.target.files[0].type)
            .then(snapshot => {
                console.log(snapshot)
                fullPath = snapshot.metadata.fullPath
                getDownloadURLForPicture(snapshot.metadata.fullPath)
                .then(dbRes => {
                    console.log(dbRes)
                    setImageUrl(dbRes)
                    updateRecipe(props.recipeId, {
                        recipePictureUrl: dbRes,
                        fullPath
                    })
                })
            })
        } else {
            tempRecipeData.recipePictureData = e.target.files[0]
            setRecipeData(tempRecipeData)
            addRecipePicture(recipePictureName, e.target.files[0], e.target.files[0].type)
            .then(snapshot => {
                tempRecipeData.fullPath = snapshot.metadata.fullPath
                getDownloadURLForPicture(snapshot.metadata.fullPath)
                .then(dbRes => {
                    tempRecipeData.recipePictureUrl = dbRes
                    setRecipeData(tempRecipeData)
                    setImageUrl(dbRes)
                })
            })
        }
    }

    const handleSummaryChange = (e) => {
        if(e.target.value.length <= 200) { 
        const tempRecipeData = {...recipeData}
        tempRecipeData["summary"] = e.target.value
        setRecipeData(tempRecipeData)
        setTotalSummaryCount(e.target.value.length)
        }
    }

    const handleRecipeTypeChange = e => {
        const tempRecipeData = {...recipeData}
        tempRecipeData["type"] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleSourceChange = e => {
        const tempRecipeData = {...recipeData}
        tempRecipeData["source"] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleTitleChange = (e) => {
        const tempRecipeData = {...recipeData}
        tempRecipeData["title"] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handlePrepTimeChange = (e) => {
        const tempRecipeData = {...recipeData}
        tempRecipeData["prepTime"] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleCookTimeChange = (e) => {
        const tempRecipeData = {...recipeData}
        tempRecipeData["cookTime"] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleServingChange = (e) => {
        const tempRecipeData = {...recipeData}
        tempRecipeData["servings"] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleTagChange = (e) => {
        const tempRecipeData = {...recipeData}
        const {
            target: { value },
          } = e;
          tempRecipeData.tags = typeof value === 'string' ? value.split(',') : value
          setRecipeData(tempRecipeData)
          console.log(tempRecipeData.tags)
    }

    const handleIngredientChange = (e, index,value) => {
        const tempRecipeData = {...recipeData}
        tempRecipeData.ingredients[index][value] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleInstructionChange = (e, index) => {
        const tempRecipeData = {...recipeData}
        tempRecipeData.instructions[index] = e.target.value
        setRecipeData(tempRecipeData)
    }

    const handleAddIngredient = () => {
        const tempRecipeData = {...recipeData}
        tempRecipeData.ingredients.push({
            amount: "1/8",
            measurement: "tsp.",
            ingredient: ""
        })
        setRecipeData(tempRecipeData)
    }

    const handleAddInstruction = () => {
        const tempRecipeData = {...recipeData}
        tempRecipeData.instructions.push("")
        setRecipeData(tempRecipeData)
    }

    const handleRemoveIngredient = () => {
        if(recipeData.ingredients.length > 1) {
        const tempRecipeData = {...recipeData}
        tempRecipeData.ingredients.pop()
        setRecipeData(tempRecipeData)
        }
    }

    const handleRemoveInstruction = () => {
        if(recipeData.instructions.length > 1) {
        const tempRecipeData = {...recipeData}
        tempRecipeData.instructions.pop()
        setRecipeData(tempRecipeData)
        }
    }

    const handleDeleteRecipe = () => {
        deleteRecipe(props.recipeId)
        .then(() => {
            if(recipeData.fullPath) {
            deleteImage(recipeData.fullPath)
            .then(() => navigateHome())
            } else {
                navigateHome()
            }
        })
    }

    const addRecipeOnSubmit = (e) => {

        let recipeDbId = ""

        e.preventDefault()
        // title, prepTime, cookTime, ingredients, instructions, userPicture, userName, userDbId
            addRecipe(
                recipeData.summary,
                recipeData.type,
                recipeData.title,
                recipeData.prepTime,
                recipeData.cookTime,
                recipeData.servings,
                recipeData.tags,
                recipeData.ingredients,
                recipeData.instructions,
                recipeData.source,
                props.userProfilePictureUrl,
                props.userDbId,
                props.username,
                recipeData.recipePictureUrl
            )
            .then((dbRes) => {
                recipeDbId = dbRes.id
                logEvent(analytics, "create_recipe")
                if(recipeData.recipePictureData) {
                        updateRecipe(recipeDbId, {
                            recipePictureUrl: recipeData.recipePictureUrl,
                            fullPath: recipeData.fullPath
                        })
                        .then(() => {
                            navigateHome()
                        })
                } else {
                    navigateHome()
                }
            })
            .catch(err => {
                setShowAlert(true)
                setAlertMessage(err)
                setAlertSeverity("error")
                window.scrollTo(0,0)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000)
            })
    }

    const handleUpdateRecipe = () => {

        const recipeDataDestructured = {
            summary: recipeData.summary,
            title: recipeData.title,
            prepTime: recipeData.prepTime,
            cookTime: recipeData.cookTime,
            servings: recipeData.servings,
            tags: recipeData.tags,
            ingredients: recipeData.ingredients,
            instructions: recipeData.instructions,
            type: recipeData.type,
            source: recipeData.source
        }

        console.log(recipeDataDestructured)
        updateRecipe(props.recipeId, recipeDataDestructured)
        .then(() => {
            setShowAlert(true)
            setAlertMessage("Successfully updated recipe!")
            setAlertSeverity("success")
            window.scrollTo(0,0)
            setTimeout(() => {
                setShowAlert(false)
            }, 5000)
        })
        .catch((err) => {
            console.log(err)
            setShowAlert(true)
            setAlertMessage(err)
            setAlertSeverity("error")
            window.scrollTo(0,0)
            setTimeout(() => {
                setShowAlert(false)
            }, 5000)
        })
    }

        return(
            <Container sx={{paddingTop: "20px", paddingBottom: "20px"}}>
                            {showAlert &&
          <Alert severity={alertSeverity} sx={{width: "100%", top: "68px", marginBottom: "15px", boxSizing: "border-box"}}>
            {alertMessage}
          </Alert>
        }
            <Grid container>
            <Grid item xs={2}>
                    <BackArrow 
                    sendTo={backpath}
                    />
                    </Grid>
                    <Grid item xs={8}>
                        <h2 style={{textAlign: "center", marginTop: "5px"}}>Create Recipe</h2>
                    </Grid>
            </Grid>
            <Box sx={{textAlign: "center"}}>
                                {imageUrl &&
                <img style={{height: "150px", width: "150px", margin: "20px auto", borderRadius: "4px"}}src={imageUrl} />
                }
                </Box>
            <Button component="label" variant="contained" sx={{width: "100%", border: "1px lightgrey solid", borderRadius: "4px", backgroundColor: "white", color: "#166FFF"}}>
                <AddPhotoAlternateOutlinedIcon sx={{color: "#166FFF", fontSize: "40px", marginRight: "10px"}}/>
                    {props.editingRecipe ?
                    <>
                    {"Update recipe picture"}
                    </>
                    :
                    <>
                    {"Upload recipe picture"}<span style={{color: "red", fontSize: "16px"}}>*</span>
                    </>
                    }
                    <VisuallyHiddenInput id="profilePicture" type="file" onChange={(e) => handleRecipeImageChange(e)}/>
                </Button>
                <Grid className="addRecipeInput" item xs={12}>
                    <InputLabel className="addRecipeLabel">Title<span className="requiredInput">*</span></InputLabel>
                    <TextField
                        sx={{width: "100%"}}
                        id="title"
                        color="primary"
                        required
                        variant="outlined"
                        value={recipeData.title}
                        // InputLabelProps={{shrink: true}}
                        onChange={(e) => handleTitleChange(e)}
                    />
                </Grid>
                <Grid  className="addRecipeInput" xs={12}>              
                    <InputLabel className="addRecipeLabel">Recipe Type<span className="requiredInput">*</span></InputLabel>
                    <Select
                        sx={{width: "100%",'& .MuiSelect-icon': {color: "#166FFF"}}}
                        value={recipeData.type}
                        onChange={handleRecipeTypeChange}
                        IconComponent={KeyboardArrowDownIcon}
                    >
                        {Types.map(type => {
                            return(
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            )
                        })}
                    </Select>
                    </Grid>  
                <Grid  className="addRecipeInput" xs={12}>
                    <InputLabel className="addRecipeLabel">Summary</InputLabel>
                    <TextField sx={{width: "100%"}} multiline variant="outlined" id="summary" value={recipeData.summary} 
                        InputProps={{
                            endAdornment: <InputAdornment sx={{position: "absolute", bottom: 20, right: 10}}position="end">{totalSummaryCount}/200</InputAdornment>,
                        }}
                    onChange={(e) => handleSummaryChange
                    (e)}/>
                </Grid>
                <Grid container className="prepCookContainer">
                    <Grid item xs={7}>
                        <InputLabel className="addRecipeLabel">Prep Time<span className="requiredInput">*</span></InputLabel>
                        <p>How much time to prepare or marinate food?</p>
                    </Grid>
                    <Grid item xs={5} sx={{textAlign: "center", color: "white"}}>
                <TextField
                    sx={{marginTop: "10px", width: "75%", borderRadius: "4px", textAlign: "center"}}
                    id="prepTime"
                    type="number"
                    required
                    InputLabelProps={{shrink: true}}
                    value={recipeData.prepTime}
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                    }}
                    onChange={(e) => handlePrepTimeChange(e)}
                />
                </Grid>
                </Grid>
                <Grid container className="prepCookContainer">
                    <Grid item xs={7}>
                        <InputLabel className="addRecipeLabel">Cook Time<span className="requiredInput">*</span></InputLabel>
                        <p>How much time to cook the food?</p>
                    </Grid>
                    <Grid item xs={5} sx={{textAlign: "center"}}>
                <TextField
                    sx={{marginTop: "10px", width: "75%", textAlign: "center"}}
                    id="cookTime"
                    type="number"
                    required
                    value={recipeData.cookTime}
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                    }}
                    onChange={(e) => handleCookTimeChange(e)}
                />
                </Grid>
                </Grid>
                <Grid item className="addRecipeInput" xs={12}>
                <InputLabel className="addRecipeLabel">Servings<span className="requiredInput">*</span></InputLabel>
                <TextField
                    sx={{width: "100%"}}
                    id="servings"
                    className="adornmentBlue"
                    type="number"
                    required
                    value={recipeData.servings}
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">servings</InputAdornment>,
                    }}
                    onChange={(e) => handleServingChange(e)}
                />
                </Grid>
                <Grid item className="addRecipeInput" xs={12}>
                <InputLabel className="addRecipeLabel">Tags</InputLabel>
                    <Select
                        id="tags"
                        multiple
                        IconComponent={KeyboardArrowDownIcon}
                        value={recipeData.tags}
                        onChange={handleTagChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        sx={{width: "100%",'& .MuiSelect-icon': {color: "#166FFF"}}}
                    >
                        {tags.map((tag) => (
                            <MenuItem key={tag} value={tag}>
                            <Checkbox sx={{color: "#166FFF",'&.Mui-checked': {color: "#166FFF"}}} checked={recipeData.tags.indexOf(tag) > -1} />
                            <ListItemText primary={tag} />
                            </MenuItem>
                        ))}  
                    </Select>
                </Grid>
                <Grid container className="addRecipeInput" spacing={1}>
                <InputLabel sx={{display: "block", width: "100%"}} className="addRecipeLabel">Add Ingredients<span className="requiredInput">*</span></InputLabel>
                {recipeData.ingredients.map((element, index) => {
                    let border = "1px lightgrey solid"

                    if(index === recipeData.ingredients.length-1) {
                        border = "none"
                    }

                    return (
                        <Grid container key={index}sx={{ width: "100%", borderBottom: border, margin: 0}}>
                            <Grid item xs={6} md={4}>
                        <FormControl sx={{width: "100%"}}>
                        <Select required value={element.amount} labelId="amount" IconComponent={KeyboardArrowDownIcon} input={<OutlinedInput/>} sx={{width: "100%",'& .MuiSelect-icon': {color: "#166FFF"}}} name="amount" id="amount" onChange={(e) => handleIngredientChange(e, index, "amount")}>
                            <MenuItem value="1/8">1/8</MenuItem>
                            <MenuItem value="1/4">1/4</MenuItem>
                            <MenuItem value="1/3">1/3</MenuItem>
                            <MenuItem value="1/2">1/2</MenuItem>
                            <MenuItem value="2/3">2/3</MenuItem>
                            <MenuItem value="3/4">3/4</MenuItem>
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="1.5">1 1/2</MenuItem>
                            <MenuItem value="2">2</MenuItem>
                            <MenuItem value="2.5">2 1/2</MenuItem>
                            <MenuItem value="3">3</MenuItem>
                            <MenuItem value="4">4</MenuItem>
                            <MenuItem value="5">5</MenuItem>
                        </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4}>
                        <FormControl sx={{width: "100%"}}>
                    <Select value={element.measurement} IconComponent={KeyboardArrowDownIcon} sx={{width: "100%", "&.MuiSelect-select": {paddingRight: "0px"},'& .MuiSelect-icon': {color: "#166FFF"}}} labelId="measurement" input={<OutlinedInput/>} name="measurement" id="measurement" onChange={(e) =>   handleIngredientChange(e, index, "measurement")}>
                            <MenuItem value="tsp.">tsp.</MenuItem>
                            <MenuItem value="Tbsp.">Tbsp.</MenuItem>
                            <MenuItem value="Cup(s)">Cup(s)</MenuItem>
                            <MenuItem value="lbs.">lbs.</MenuItem>
                            <MenuItem value="pieces">pieces</MenuItem>
                        </Select>
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                                <TextField required value={element.ingredient} sx={{width: "100%"}} type='text' id='ingredient' placeholder="Ingredient" onChange={(e) => handleIngredientChange(e, index, "ingredient")}/>
                            </Grid>
                        </Grid>
                    )
                })}
                <Button variant="text" id="addIngredient" onClick={() => handleAddIngredient()}><AddIcon/> Add</Button>
                <Button variant="text" id="removeIngredient" onClick={handleRemoveIngredient}><RemoveIcon/> Remove</Button>
                </Grid>
                    <Grid xs={12}>
                    <InputLabel className="addRecipeLabel">Steps<span className="requiredInput">*</span></InputLabel>
                    </Grid>
                    <Grid container xs={12}>
                {recipeData.instructions.map((element, index) => {
                    return (
                        <Grid item key={index} xs={12} sx={{margin:"5px 0px", width: "100%"}}>
                        <TextField required multiline placeholder={`Step #${index+1}`} value={element} type='text' style={{width: "100%"}}id='instructions' onChange={(e) => handleInstructionChange(e, index)}/>
                        </Grid>
                    )
                })}
                </Grid>
                <Grid xs={12}>
                <Button variant="text" id="addInstruction" onClick={handleAddInstruction}><AddIcon/> Add</Button>
                <Button variant="text" id="removeInstruction" onClick={handleRemoveInstruction}><RemoveIcon/> Remove</Button>
                </Grid>
                <Grid className="addRecipeInput" item xs={12}>
                    <InputLabel className="addRecipeLabel">Source<span className="requiredInput">*</span></InputLabel>
                    <TextField
                        sx={{width: "100%"}}
                        color="primary"
                        required
                        variant="outlined"
                        helperText='Where did you get this recipe? You can say "Original Recipe" or add a link to where you sourced the recipe.'
                        value={recipeData.source}
                        onChange={(e) => handleSourceChange(e)}
                    />
                </Grid>
                <Grid item xs={6} sx={{mt: "30px"}}>
                {props.recipeId ?
                <Button sx={{width: "100%"}} variant="contained" type="submit" onClick={() => handleUpdateRecipe()}>Update Recipe</Button>
                                :
                <Button sx={{width: "100%"}}variant="contained" type="submit" onClick={(e) => addRecipeOnSubmit(e)}>Add Recipe</Button>
                }
                </Grid>
                {props.recipeId &&
                <Grid xs={6} sx={{mt: "10px"}}>
                <Button sx={{width: "100%"}} onClick={() => handleDeleteRecipe()}>Delete Recipe</Button>
                </Grid>
                }
            </Container>
        )
}

export default AddRecipe